import {Component, OnInit} from '@angular/core';
import { TemplateService } from '../template.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [
    AuthService
  ]
})
export class HeaderComponent implements OnInit {

  searchModel = '';
  isCollapse = false;
  isOpen = false;
  searchActived = false;
  notificationCounter = 0;

  constructor(
    private auth: AuthService,
    private tplSvc: TemplateService
  ) { }

  ngOnInit(): void {
    this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);
  }

  toggleSideNavCollapse() {
    this.isCollapse = !this.isCollapse;
    this.tplSvc.toggleSideNavCollapse(this.isCollapse);
  }

  toggleSidePanelOpen() {
    this.isOpen = !this.isOpen;
    this.tplSvc.toggleSidePanelOpen(this.isOpen);
  }

  toggleSearch () {
    this.searchActived = !this.searchActived;
    console.log(this.searchActived)
  }

  logOut() {
    this.auth.logout();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { HotToastModule } from '@ngneat/hot-toast';

// Common Libraries
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { RangeDatepickerComponent } from './range-datepicker/range-datepicker.component';
import { GeneralMethods } from './general';
import { MonthNavigatorComponent } from './month-navigator/month-navigator.component';

// Angular Material
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NgScrollbarModule,
    NgbModule,
    TablePaginationComponent,
    LoadingSpinnerComponent,
    RangeDatepickerComponent,
    MonthNavigatorComponent,
    FlexLayoutModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
  ],
  imports: [
    RouterModule,
    CommonModule,
    PerfectScrollbarModule,
    NgScrollbarModule,
    NgbModule,
    FormsModule,
    ToastrModule.forRoot(),
    HotToastModule.forRoot(),
  ],
  declarations: [
    TablePaginationComponent,
    LoadingSpinnerComponent,
    RangeDatepickerComponent,
    MonthNavigatorComponent
  ],
  providers: [
    GeneralMethods
  ]
})

export class SharedModule { }

import { Component, ViewChild, OnInit, ElementRef, Renderer2,  Output, EventEmitter, } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NgbInputDatepicker, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GeneralMethods} from '@app/shared/general';

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss']
})

export class RangeDatepickerComponent implements OnInit {
  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  currentDateSelected: any;
  @ViewChild('d') input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;
  @Output() rangeSelected: EventEmitter<{}> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private parserFormatter: NgbDateParserFormatter,
    private common: GeneralMethods
  ) {}

  ngOnInit(): void {
    const now = new Date();
    this.startDate = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate()};
    this.minDate = {year: 2018, month: now.getMonth() + 1, day: now.getDate()};
  }

  equals(one: NgbDateStruct, two: NgbDateStruct): boolean {
    return one && two && two.year === one.year && two.month === one.month && two.day === one.day;
  }

  before(one: NgbDateStruct, two: NgbDateStruct): boolean {
    return !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day < two.day : one.month < two.month : one.year < two.year;
  }

  after(one: NgbDateStruct, two: NgbDateStruct): boolean {
    return !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
      ? false : one.day > two.day : one.month > two.month : one.year > two.year;
  }

  isHovered(date): boolean {
    return this.fromDate && !this.toDate && this.hoveredDate && this.after(date, this.fromDate) && this.before(date, this.hoveredDate);
  }

  isInside(date): boolean {
    return this.after(date, this.fromDate) && this.before(date, this.toDate);
  }

  isFrom(date): boolean {
    return this.equals(date, this.fromDate);
  }

  isTo(date): boolean {
    return this.equals(date, this.toDate);
  }

  onDateSelection(date: NgbDateStruct): void {
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && this.after(date, this.fromDate)) {
      this.toDate = date;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate) {
      parsed += this.parserFormatter.format(this.fromDate);
    }
    if (this.toDate) {
      parsed += ' - ' + this.parserFormatter.format(this.toDate);
      const startDate = this.common.formatDate(this.fromDate);
      const endDate = this.common.formatDate(this.toDate);
      this.updateEmitter({
        startDate,
        endDate
      });
    }

    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
  }

  updateEmitter(data: any): void {
    this.rangeSelected.emit(data);
  }

}

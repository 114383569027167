import { Component, OnInit } from '@angular/core';
import { TemplateService } from '@app/template/template.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './common-layout.component.html'
})

export class CommonLayoutComponent implements OnInit {
  isCollapse = false;
  rtlActived = false;

  constructor(private tplSvc: TemplateService) {

  }

  ngOnInit() {
    this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
  }
}

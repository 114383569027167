import { LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { TemplateModule } from './template/template.module';
import { TemplateService } from './template/template.service';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

// Layout Component
import { CommonLayoutComponent } from './shared/common-layout.component';
import { AuthenticationLayoutComponent } from './shared/authentication-layout.component';

// Routing Module
import { AppRoutingModule } from './app.routing';

// App Component
import { AppComponent } from './app.component';

// Routes and JwT services
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { RoleGuardService } from './auth/role-guard.service';
import { TokenInterceptorService } from './auth/token-interceptor.service';

registerLocaleData(es);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    TemplateModule,
  ],
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    AuthenticationLayoutComponent,
  ],
  providers: [
    TemplateService,
    AuthService,
    AuthGuardService,
    RoleGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'es-MX'}
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }

<div class="header navbar">
  <div class="header-container">
    <ul class="nav-left">
      <li>
        <a class="side-nav-toggle" (click)="toggleSideNavCollapse()" href="javascript:void(0);">
          <i class="ti-view-grid"></i>
        </a>
      </li>
      <li class="search-box" [ngClass]="{'active': searchActived}">
        <a class="search-toggle no-pdd-right" href="javascript:void(0);" (click)="toggleSearch()">
          <i class="search-icon ti-search pdd-right-10"></i>
          <i class="search-icon-close ti-close pdd-right-10"></i>
        </a>
      </li>
      <li class="search-input" [ngClass]="{'active': searchActived}">
        <input class="form-control" [(ngModel)]="searchModel" type="text" placeholder="Search...">
        <div class="advanced-search" [ngClass]="{'active': searchModel.length > 0}">
          <div class="search-wrapper">
            <div class="pdd-vertical-10">
              <span class="display-block mrg-vertical-5 pdd-horizon-20 text-gray">
                <i class="ti-user pdd-right-5"></i>
                <span>People</span>
              </span>
              <ul class="list-unstyled list-info">
                <li>
                  <a href="">
                    <img class="thumb-img" src="assets/images/avatars/user-1.jpg" alt="">
                    <div class="info">
                      <span class="title">Jordan Hurst</span>
                      <span class="sub-title"><i class="ti-location-pin"></i>44 Shirley Ave. West Chicago</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="">
                    <img class="thumb-img" src="assets/images/avatars/user-1.jpg" alt="">
                    <div class="info">
                      <span class="title">Jennifer Watkins</span>
                      <span class="sub-title"><i class="ti-location-pin"></i>514 S. Magnolia St. Orlando</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="">
                    <img class="thumb-img" src="assets/images/avatars/user-1.jpg" alt="">
                    <div class="info">
                      <span class="title">Michael Birch</span>
                      <span class="sub-title"><i class="ti-location-pin"></i>70 Bowman St. South Windsor</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="mrg-horizon-20 border top"></div>
            <div class="pdd-vertical-10">
              <span class="display-block mrg-vertical-5 pdd-horizon-20 text-gray">
                <i class="ti-rss pdd-right-5"></i>
                <span>Post</span>
              </span>
              <ul class="list-unstyled list-info">
                <li>
                  <a href="">
                    <img class="thumb-img" src="assets/images/avatars/user-1.jpg" alt="">
                    <div class="info">
                      <span class="title">Artoo expresses his relief</span>
                      <span class="sub-title">Oh, thank goodness we're coming out...</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="">
                    <img class="thumb-img" src="assets/images/avatars/user-1.jpg" alt="">
                    <div class="info">
                      <span class="title">Ready for some power?</span>
                      <span class="sub-title">Lord Vader. You may take Caption So...</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="search-footer">
            <span>You are Searching for '<b class="text-dark"><span class="serach-text-bind"></span></b>'</span>
          </div>
        </div>
      </li>
    </ul>
    <ul class="nav-right">
      <li class="user-profile dropdown">
        <a class="dropdown-toggle" href="#" data-toggle="dropdown">
          <img class="profile-img img-fluid" src="assets/images/avatars/user-1.jpg" alt="">
          <div class="user-info">
            <span class="name pdd-right-5">Nate Leong</span>
            <i class="ti-angle-down font-size-10"></i>
          </div>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="">
              <i class="ti-settings pdd-right-10"></i>
              <span>Setting</span>
            </a>
          </li>
          <li>
            <a href="">
              <i class="ti-user pdd-right-10"></i>
              <span>Profile</span>
            </a>
          </li>
          <li>
            <a href="">
              <i class="ti-email pdd-right-10"></i>
              <span>Inbox</span>
            </a>
          </li>
          <li class="divider" role="separator"></li>
          <li>
            <a href="javascript:void(0)" (click)="logOut()">
              <i class="ti-power-off pdd-right-10"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="notifications dropdown">
        <span class="counter" *ngIf="notificationCounter > 0">{{notificationCounter}}</span>
        <a class="dropdown-toggle" href="#" data-toggle="dropdown">
          <i class="ti-bell"></i>
        </a>
        <ul class="dropdown-menu" *ngIf="notificationCounter < 1">
          <li class="notice-header">
            <i class="ti-bell pdd-right-10"></i>
            <span>There are no notifications at the time.</span>
          </li>
        </ul>
        <ul class="dropdown-menu" *ngIf="notificationCounter > 0">
          <li class="notice-header">
            <i class="ti-bell pdd-right-10"></i>
            <span>Notifications</span>
          </li>
          <ul class="list-info overflow-y-auto relative scrollable" [perfectScrollbar]="">
            <li>
              <a href="">
                <img class="thumb-img" src="assets/images/avatars/user-1.jpg" alt="">
                <div class="info">
                  <span class="title">
                    <span class="font-size-14 text-semibold">Jennifer Watkins</span>
                    <span class="text-gray">commented on your</span>
                    <span class="text-dark">post</span>
                  </span>
                  <span class="sub-title">5 mins ago</span>
                </div>
              </a>
            </li>
            <li>
              <a href="">
                <img class="thumb-img" src="assets/images/avatars/user-1.jpg" alt="">
                <div class="info">
                  <span class="title">
                    <span class="font-size-14 text-semibold">Samuel Field</span>
                    <span class="text-gray">likes your</span>
                    <span class="text-dark">photo</span>
                  </span>
                  <span class="sub-title">8 hours ago</span>
                </div>
              </a>
            </li>
            <li>
              <a href="">
                <span class="thumb-img bg-primary">
                  <span class="text-white">M</span>
                </span>
                <div class="info">
                  <span class="title">
                    <span class="font-size-14 text-semibold">Michael Birch</span>
                    <span class="text-gray">likes your</span>
                    <span class="text-dark">photo</span>
                  </span>
                  <span class="sub-title">5 hours ago</span>
                </div>
              </a>
            </li>
            <li>
              <a href="">
                <span class="thumb-img bg-success">
                  <span class="text-white">
                    <i class="fa fa-paper-plane-o"></i>
                  </span>
                </span>
                <div class="info">
                  <span class="title">
                    <span class="font-size-14 text-semibold">Message sent</span>
                  </span>
                  <span class="sub-title">8 hours ago</span>
                </div>
              </a>
            </li>
            <li>
              <a href="">
                <span class="thumb-img bg-info">
                  <span class="text-white">
                    <i class="ti-user"></i>
                  </span>
                </span>
                <div class="info">
                  <span class="title">
                    <span class="font-size-14 text-semibold">Admin</span>
                    <span class="text-gray">Welcome on board</span>
                  </span>
                  <span class="sub-title">8 hours ago</span>
                </div>
              </a>
            </li>
          </ul>
          <li class="notice-footer">
            <span>
              <a class="text-gray" href="">
                Check all notifications
                <i class="ei-right-chevron pdd-left-5 font-size-10"></i>
              </a>
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

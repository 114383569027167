<div class="side-nav" sideBar>
  <div class="side-nav-inner">
    <div class="side-nav-logo">
      <a href="" [routerLink]="['/dashboard']">
        <div class="logo logo-dark" style="background-image: url('assets/images/logo/logo.png')"></div>
        <div class="logo logo-white" style="background-image: url('assets/images/logo/logo-white.png')"></div>
      </a>
      <div class="mobile-toggle side-nav-toggle">
        <a (click)="toggleSideNavCollapse()" href="javascript:void(0);">
          <i class="ti-arrow-circle-left"></i>
        </a>
      </div>
    </div>
    <ul class="side-nav-menu scrollable" [perfectScrollbar]="">
      <li class="nav-item" routerLinkActive="active">
        <a class="mrg-top-30" href="" [routerLink]="['/dashboard']">
          <span class="icon-holder"><i class="ti-home"></i></span>
          <span class="title">Dashboard</span>
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder"><i class="ti-credit-card"></i></span>
          <span class="title">Cuentas</span>
          <span class="arrow"><i class="ti-angle-right"></i></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="" [routerLink]="['/accounts/list']">Listado</a>
          </li>
          <!--<li>
            <a href="" [routerLink]="['/accounts/list']">Movimientos</a>
          </li>-->
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder"><i class="ti-receipt"></i></span>
          <span class="title">Gastos</span>
          <span class="arrow"><i class="ti-angle-right"></i></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="" [routerLink]="['/expenses/list']">Listado</a>
          </li>
          <li>
            <a href="" [routerLink]="['/expenses/category-list']">Por Categoria</a>
          </li>
          <li>
            <a href="" [routerLink]="['/expenses/credit-card-statement']">Estados de cuenta</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder"><i class="ti-package"></i></span>
          <span class="title">Inventario</span>
          <span class="arrow"><i class="ti-angle-right"></i></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="" [routerLink]="['/inventory/articles-list']">Articulos</a>
          </li>
          <li>
            <a href="" [routerLink]="['/inventory/merchants-list']">Beneficiarios</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder"><i class="ti-money"></i></span>
          <span class="title">Ingresos</span>
          <span class="arrow"><i class="ti-angle-right"></i></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="" [routerLink]="['/incomes/list']">Listado</a>
          </li>
        </ul>
      </li>
      <li class="nav-item dropdown">
        <a class="dropdown-toggle" href="javascript:void(0);">
          <span class="icon-holder"><i class="ti-file"></i></span>
          <span class="title">Recetas</span>
          <span class="arrow"><i class="ti-angle-right"></i></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="" [routerLink]="['/recipes/list']">Listado publico</a>
          </li>
          <li>
            <a href="" [routerLink]="['/admin-recipes/list']">Listado Admin</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class SpinnerService {
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  show() {
    this.change.emit(true);
  }

  hide() {
    this.change.emit(false);
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AuthGuardService } from './auth/auth-guard.service';
import { RoleGuardService } from './auth/role-guard.service';
// Layouts
import { CommonLayoutComponent } from './shared/common-layout.component';
import { AuthenticationLayoutComponent } from './shared/authentication-layout.component';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: CommonLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'accounts',
        loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
        canActivate: [AuthGuardService, RoleGuardService],
        data: { expectedRole: 'admin' }
      },
      {
        path: 'expenses',
        loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'incomes',
        loadChildren: () => import('./incomes/incomes.module').then(m => m.IncomesModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'admin-recipes',
        loadChildren: () => import('./recipes/admin-control/admin-control-recipe.module').then(m => m.AdminRecipeModule),
        canActivate: [AuthGuardService]
      },
    ],
  },
  {
    path: '',
    component: AuthenticationLayoutComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: 'recipes',
        loadChildren: () => import('./recipes/public-recipes/public-recipes.module').then(m => m.PublicRecipesModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [
    RouterModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

@Injectable()
export class GeneralMethods {

  constructor() {

  }

  formatDate(date: any): string {
    let stringDate = '';
    if (date) {
      stringDate += date.year ? date.year + '-' : '';
      stringDate += date.month ? this.padNumber(date.month) + '-' : '';
      stringDate += this.padNumber(date.day);
    }
    return stringDate;
  }

  padNumber(value: number) {
    if (value) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

  getPaidImg(isPayOut: boolean) {
    const url = 'assets/images/others';
    if (isPayOut) {
      return `${url}/paid2.svg`;
    } else {
      return `${url}/paid.svg`;
    }
  }

  valueFormatting(value: number): string {
    return `$ ${Math.round(value).toLocaleString()}`;
  }
}

// @ts-nocheck
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { UserModel, CredentialsModel } from '../user/user.model';

@Injectable()
export class AuthService {
  API_URL = environment.url;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {}

  public isAuthenticated(): boolean {
    const expiresIn = localStorage.getItem('expiresIn');
    if (!expiresIn) {
      return false;
    }
    return new Date() < new Date(expiresIn);
  }

  private setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  private setUserRoles(userInfo: any): void {
    localStorage.setItem('roles', JSON.stringify(userInfo));
  }

  private setExpiresAt(expiresIn: string): void {
    localStorage.setItem('expiresIn', expiresIn);
  }

  public getUserRoles(): any {
    return JSON.parse(localStorage.getItem('roles'));
  }

  public setUser(token: string, roles: any, expiresIn: string, username: string): void {
    this.setToken(token);
    this.setUserRoles(roles);
    this.setExpiresAt(expiresIn);
  }

  public signup(user: UserModel): Observable<any> {
    return this.http.post(`${this.API_URL}/auth/signup`, { ...user });
  }

  public login(credentials: CredentialsModel): Observable<any> {
    return this.http.post(`${this.API_URL}/auth/login`, { ...credentials });
  }

  public logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    localStorage.removeItem('expiresIn');
    this.router.navigate(['authentication/login']);
  }

  public isAdmin(): boolean {
    const userInfo = this.getUserRoles();
    if (!userInfo) {
      return false;
    }
    return userInfo.role === 'admin';
  }

  public userHasRole(expectedRole: string): boolean {
    const userRoles = this.getUserRoles();
    return expectedRole === userRoles;
  }
}

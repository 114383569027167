import { OnInit, Component, EventEmitter, Output } from '@angular/core';
import { startOfMonth, endOfMonth, addMonths, subMonths, format } from 'date-fns';

@Component({
  selector: 'app-month-navigator',
  templateUrl: './month-navigator.component.html',
  styleUrls: ['./month-navigator.component.scss']
})
export class MonthNavigatorComponent implements OnInit {
  date = new Date(); // current date

  @Output() dateChange = new EventEmitter<{ startDate: string, endDate: string }>();

  constructor() { }

  // send the initial date
  ngOnInit() {
    this.emitDateChange();
  }

  prevMonth(): void {
    this.date = subMonths(this.date, 1);
    this.emitDateChange();
  }

  nextMonth(): void {
    this.date = addMonths(this.date, 1);
    this.emitDateChange();
  }

  emitDateChange(): void {
    const startDate = format(startOfMonth(this.date), 'yyyy-MM-dd');
    const endDate = format(endOfMonth(this.date), 'yyyy-MM-dd');

    this.dateChange.emit({ startDate, endDate });
  }

  get display(): string {
    return format(this.date, 'MMMM');
  }
}

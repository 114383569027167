<form class="form-inline">
  <div class="form-group width-100">
    <div class="input-group" style="width: 100%">
      <input
          class="form-control"
          #myRangeInput=""
          placeholder="yyyy-mm-dd - yyyy-mm-dd"
          name="dp"
          ngbDatepicker
          outsideDays="hidden"
          [(ngModel)]="currentDateSelected"
          [dayTemplate]="t"
          [autoClose]="'outside'"
          [displayMonths]="2"
          [maxDate]="maxDate"
          [minDate]="minDate"
          (click)="d.toggle()"
          #d="ngbDatepicker">
      <ng-template #t let-date="date" let-focused="focused">
        <span class="custom-day"
              [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (click)="onDateSelection(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../template.service';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html'
})
export class SideNavComponent implements OnInit {

  isCollapse = false;
  public menus = [
    {
      icon: 'ti-home',
      title: 'Dashboard',
      route: '/dashboard',
      isDropdown: false
    },
    {
      icon: 'ti-credit-card',
      title: 'Cuentas',
      isDropdown: true,
      subItems: [
        {title: 'Listado', route: '/accounts/list'}
        // {title: 'Movimientos', route: '/accounts/movements'}
      ]
    },
    {
      icon: 'ti-receipt',
      title: 'Gastos',
      isDropdown: true,
      subItems: [
        {title: 'Listado', route: '/expenses/list'},
        {title: 'Por Categoria', route: '/expenses/category-list'},
        {title: 'Estados de cuenta', route: '/expenses/credit-card-statement'}
      ]
    },
    {
      icon: 'ti-package',
      title: 'Inventario',
      isDropdown: true,
      subItems: [
        {title: 'Articulos', route: '/inventory/articles-list'},
        {title: 'Beneficiarios', route: '/inventory/merchants-list'}
      ]
    },
    {
      icon: 'ti-money',
      title: 'Ingresos',
      isDropdown: true,
      subItems: [
        {title: 'Listado', route: '/incomes/list'}
      ]
    },
    {
      icon: 'ti-file',
      title: 'Recetas',
      isDropdown: true,
      subItems: [
        {title: 'Listado publico', route: '/recipes/list'},
        {title: 'Listado Admin', route: '/admin-recipes/list'}
      ]
    }
  ];


  constructor(private tplSvc: TemplateService, private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (window.innerWidth < 992) {
          this.tplSvc.toggleSideNavCollapse(false);
        }
      }
    });
  }

  ngOnInit() {
    this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
  }

  toggleSideNavCollapse() {
    this.isCollapse = !this.isCollapse;
    this.tplSvc.toggleSideNavCollapse(this.isCollapse);
  }
}

<div class="pagination-row" id="pagination-component">
  <!-- Search section -->
  <div class="pagination-section" *ngIf="showInputSearch">
    <div class="pagination-item mrg-right-15" [ngClass]="{'no-display': isSmallWidth}">
      <span>Search:</span>
    </div>
    <div class="pagination-item">
      <input class="form-control" type="text" placeholder="{{inputPlaceHolderText}}" [(ngModel)]="searchString" (ngModelChange)="onInputType.next($event)">
    </div>
    <div class="pagination-item" [ngClass]="{'no-display': isSmallWidth}">
      <i class="ti-close pointer" (click)="clearInput()"></i>
    </div>
  </div>

  <!-- Pagination section -->
  <div class="pagination-section">
    <div class="pagination-item" [ngClass]="{'no-display': isSmallWidth}">
      <span>Show:</span>
    </div>
    <div class="pagination-item">
      <select class="form-control" (change)="onPageNumberSelected($event)" [(ngModel)]="limit">
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="100">100</option>
      </select>
    </div>
    <div class="pagination-item page-offset-controllers">
      <a href="javascript: void(0)" (click)="goFirst()" [ngClass]="{'control-disable': currentPage === 1}" class="page-button-controller">
        <i class="ei-left-chevron-2"></i>
      </a>
      <a href="javascript: void(0)" (click)="goPrevious()" [ngClass]="{'control-disable': currentPage === 1}" class="page-button-controller">
        <i class="ti-angle-left"></i>
      </a>
      <!-- Page buttons -->
      <ng-container *ngFor="let item of pages">
        <a href="javascript: void(0)" (click)="goPage(item.number)" [ngClass]="isCurrentPage(item)" class="page-button-controller">
          <span>{{item.number}}</span>
        </a>
      </ng-container>
      <a href="javascript: void(0)" (click)="goNext()" [ngClass]="{'control-disable': currentPage === chunk}" class="page-button-controller">
        <i class="ti-angle-right"></i>
      </a>
      <a href="javascript: void(0)" (click)="goLast()" [ngClass]="{'control-disable': currentPage === chunk}" class="page-button-controller">
        <i class="ei-right-chevron-2"></i>
      </a>
    </div>
    <div class="pagination-item" [ngClass]="{'no-display': isSmallWidth}">
      <span>Item&nbsp;<b>{{offset + 1}}</b>&nbsp; to&nbsp;<b>{{lastItemNumberOnPage}}</b>&nbsp; of&nbsp;<b>{{count}}</b></span>
    </div>
  </div>
</div>

<div class="app" [ngClass]="{'is-collapsed': isCollapse, 'rtl': rtlActived}">
    <div class="layout header-dark">

        <div class="side-nav-dark">
            <!-- Side Nav START -->
            <app-side-nav></app-side-nav>
            <!-- Side Nav END -->

            <!-- Page Container START -->
            <div class="page-container">

                <!-- Top Nav START -->
                <app-header></app-header>
                <!-- Top Nav END -->

                <!-- Content Wrapper START -->
                <div class="main-content">
                    <router-outlet></router-outlet>
                </div>
                <!-- Content Wrapper END -->
            </div>
            <!-- Page Container END -->
         </div>
    </div>
</div>

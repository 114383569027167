<div class="text-center" *ngIf="show">
  <svg
      class="loading"
      version="1.1"
      viewBox="0 0 50 50"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      y="0px">
    <path
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
      <animateTransform
          attributeName="transform"
          attributeType="xml"
          dur="1s"
          from="0 25 25"
          repeatCount="indefinite"
          to="360 25 25"
          type="rotate">
      </animateTransform>
    </path>
  </svg>
</div>
